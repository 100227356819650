var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"basic"},[_vm._m(0),_c('div',{staticClass:"info"},[_c('div',[_c('p',{staticStyle:{"min-width":"300px"}},[_c('span',{staticStyle:{"width":"56px"}},[_vm._v(_vm._s(_vm.data.nicheCategory == 1 ? '客户' : '报备渠道'))]),(_vm.data.nicheCategory == 1)?_c('em',{staticClass:"customer",on:{"click":_vm.onDetails}},[_vm._v(_vm._s(_vm.data.companyName || '- -'))]):_c('em',{staticClass:"customer",staticStyle:{"color":"#333"}},[_vm._v(_vm._s(_vm.data.channelManageName || '- -'))])]),_c('p',[_c('span',[_vm._v("合作时间")]),(_vm.businessInfoUpdate && _vm.data.adminId == _vm.adminId)?_c('el-date-picker',{staticStyle:{"width":"150px"},attrs:{"size":"mini","type":"date","placeholder":"选择日期","value-format":"yyyy-MM-dd HH:mm:ss"},on:{"change":function (val) {
                            _vm.reportUpdate('estimateCooperateTime', val);
                        }},model:{value:(_vm.data.estimateCooperateTime),callback:function ($$v) {_vm.$set(_vm.data, "estimateCooperateTime", $$v)},expression:"data.estimateCooperateTime"}}):_c('em',[_vm._v(_vm._s(_vm.data.estimateCooperateTime || '- -'))])],1),_c('p',{staticStyle:{"position":"relative"}},[_c('span',[_vm._v("备注")]),(_vm.businessInfoUpdate && _vm.data.adminId == _vm.adminId)?_c('el-input',{staticStyle:{"width":"200px","left":"53px"},attrs:{"size":"mini","type":"textarea","rows":"3","placeholder":"请输入内容"},on:{"blur":function () {
                            _vm.reportUpdate('reportRemark', _vm.data.reportRemark);
                        }},model:{value:(_vm.data.reportRemark),callback:function ($$v) {_vm.$set(_vm.data, "reportRemark", $$v)},expression:"data.reportRemark"}}):_c('em',{staticClass:"el-textarea",staticStyle:{"width":"200px","height":"56px","overflow-y":"auto"}},[_vm._v(_vm._s(_vm.data.reportRemark || '- -'))])],1),_c('p',{staticStyle:{"position":"relative","width":"25%"}},[_c('span',[_vm._v("报备理由")]),(_vm.businessInfoUpdate && _vm.data.adminId == _vm.adminId)?_c('el-input',{staticStyle:{"width":"200px"},attrs:{"size":"mini","type":"textarea","rows":"3","placeholder":"请输入内容"},on:{"blur":function () {
                            _vm.reportUpdate('reportReason', _vm.data.reportReason);
                        }},model:{value:(_vm.data.reportReason),callback:function ($$v) {_vm.$set(_vm.data, "reportReason", $$v)},expression:"data.reportReason"}}):_c('em',{staticClass:"el-textarea",staticStyle:{"width":"200px","height":"56px","overflow-y":"auto"}},[_vm._v(_vm._s(_vm.data.reportReason || '- -'))])],1)]),_c('div',[_c('p',{staticStyle:{"min-width":"300px"}},[_c('span',{staticStyle:{"width":"56px"}},[_vm._v("联系人")]),(_vm.businessInfoUpdate && _vm.data.adminId == _vm.adminId)?_c('el-select',{staticStyle:{"width":"150px"},attrs:{"size":"mini","placeholder":"选择或直接输入","clearable":"","allow-create":"","filterable":"","remote":"","value-key":"contactName"},on:{"change":_vm.getContactPhone},model:{value:(_vm.contactData),callback:function ($$v) {_vm.contactData=$$v},expression:"contactData"}},_vm._l((_vm.contactList),function(item){return _c('el-option',{key:item.index,attrs:{"label":item.contactName,"value":item}})}),1):_c('em',[_vm._v(_vm._s(_vm.contactData || '- -'))])],1),_c('p',[_c('span',{staticStyle:{"width":"56px"}},[_vm._v("年限")]),(_vm.businessInfoUpdate && _vm.data.adminId == _vm.adminId)?_c('el-select',{staticStyle:{"width":"150px"},attrs:{"size":"mini","placeholder":"请选择"},on:{"change":function (val) {
                            _vm.reportUpdate('estimateTerm', val);
                        }},model:{value:(_vm.data.estimateTerm),callback:function ($$v) {_vm.$set(_vm.data, "estimateTerm", $$v)},expression:"data.estimateTerm"}},_vm._l((_vm.$MailStatus.estimateTerm()),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1):_c('em',[_vm._v(_vm._s(_vm.data.estimateTerm || 0))])],1)]),_c('div',[_c('p',{staticStyle:{"min-width":"300px"}},[_c('span',[_vm._v("联系电话")]),(_vm.businessInfoUpdate && _vm.data.adminId == _vm.adminId)?_c('el-select',{staticStyle:{"width":"150px"},attrs:{"size":"mini","placeholder":"选择或直接输入","clearable":"","allow-create":"","filterable":"","remote":"","value-key":"contactContent"},on:{"change":function($event){return _vm.getPhoneData()}},model:{value:(_vm.contactPhoneData),callback:function ($$v) {_vm.contactPhoneData=$$v},expression:"contactPhoneData"}},_vm._l((_vm.phoneList),function(item){return _c('el-option',{key:item.index,attrs:{"label":item.contactContent,"value":item}})}),1):_c('em',[_vm._v(_vm._s(_vm.contactPhoneData || '- -'))])],1),_c('p',[_c('span',[_vm._v("预计金额")]),(_vm.businessInfoUpdate && _vm.data.adminId == _vm.adminId)?_c('el-input',{staticStyle:{"width":"150px"},attrs:{"size":"mini","placeholder":"请输入内容"},on:{"blur":function () {
                            _vm.reportUpdate(
                                'estimateAmount',
                                _vm.data.estimateAmount
                            );
                        }},model:{value:(_vm.data.estimateAmount),callback:function ($$v) {_vm.$set(_vm.data, "estimateAmount", $$v)},expression:"data.estimateAmount"}}):_c('em',[_vm._v(_vm._s(_vm.data.estimateAmount || 0))])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h2',[_c('i',{staticClass:"iconfont icon-chuchangbianhao"}),_vm._v(" 商机信息 ")])}]

export { render, staticRenderFns }