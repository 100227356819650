<template>
    <div class="basic">
        <h2>
            <!-- <i class="iconfont icon-icon_yingyongguanli"></i> -->
            <img src="@/img/report.png" alt="" />
            报备信息
        </h2>
        <div class="info">
            <div>
                <p style="min-width: 300px">
                    <span style="width: 56px">状态</span>
                    <em class="status">
                        {{
                            $MailStatus.reportStatus(data.reportStatus) +
                            '-' +
                            $MailStatus.trialStatus(data.trialStatus)
                        }}</em
                    >
                </p>
                <p>
                    <span>报备时间</span
                    >{{
                        data.reportStartTime
                            ? data.reportStartTime.slice(0, 10)
                            : '- -' || '- -'
                    }}
                </p>
                <!-- <p>
                    <span>回收时间</span>

                    <em style="font-style: normal">{{
                        data.reportExpireTime
                            ? data.reportExpireTime.slice(0, 10)
                            : '- -'
                    }}</em>
                </p> -->
                <p>
                    <span>报备库</span>
                    <el-select
                        v-model="data.libraryCategory"
                        style="width: 200px"
                        size="mini"
                        v-if="
                            reportInfoUpdate &&
                            data.reportStatus != 8 &&
                            data.reportStatus != 9
                        "
                        placeholder="请选择"
                        @change="
                            (val) => {
                                reportSetting('libraryCategory', val);
                            }
                        "
                    >
                        <el-option
                            v-for="item in FilingList"
                            :key="item.id"
                            :label="item.accountName"
                            :disabled="returnDis(item.id)"
                            :value="item.id"
                        >
                            <span style="float: left">{{
                                item.accountName
                            }}</span>
                            <span
                                style="
                                    float: right;
                                    color: #8492a6;
                                    font-size: 13px;
                                "
                                >{{
                                    Number(item.maxReportNumber) -
                                    Number(item.consumedNumber) -
                                    Number(item.channelConsumedNumber)
                                }}</span
                            >
                        </el-option>
                    </el-select>
                    <em v-else style="font-style: normal">
                        {{ data.libraryName || '- -' }}
                    </em>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { accountList, reportSetting } from '@/api/report/report.js';
export default {
    name: '',
    props: {},
    data() {
        return {
            data: {},
            FilingList: [],
            surplus: '',
            reportInfoUpdate:
                Boolean(sessionStorage.getItem('reportInfoUpdate')) || false,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.data = data;
            let obj = {
                param: {
                    productType: 4,
                },
                pageNum: 0,
                pageSize: 0,
            };
            accountList(obj).then((res) => {
                this.FilingList = res.data.list;
                let obj = this.FilingList.find((item) => {
                    return item.id == data.libraryCategory;
                });
                this.surplus =
                    Number(obj.maxReportNumber) -
                    Number(obj.consumedNumber) -
                    Number(obj.channelConsumedNumber);
            });
        },
        reportSetting(str, val) {
            let data = {
                param: {
                    [str]: val,
                    id: this.data.id,
                },
            };
            reportSetting(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('reportGet');
                }
            });
        },
        returnDis(val) {
            if (val) {
                let obj = this.FilingList.find((item) => {
                    return item.id == val;
                });

                if (
                    Number(obj.maxReportNumber) -
                        Number(obj.consumedNumber) -
                        Number(obj.channelConsumedNumber) <=
                    0
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.basic {
    h2 {
        padding-left: 16px;
        padding-top: 20px;
        padding-bottom: 12px;
        font-size: 14px;
        font-weight: 600;
        i {
            font-weight: normal;
            color: #f7b84f;
        }
    }
    .info {
        padding-left: 31px;
        padding-bottom: 12px;
        > div {
            display: flex;
            flex-wrap: nowrap;

            p {
                width: 25%;
                font-size: 14px;
                color: #333;
                font-weight: 600;
                display: flex;
                align-items: center;
                span {
                    color: #666666;
                    font-weight: normal;
                    margin-right: 10px;
                    display: inline-block;
                }
            }
        }
    }
    .status {
        padding: 0px 10px;
        background: rgba(35, 112, 235, 0.09);
        border-radius: 10px;
        border: 1px solid #2370eb;
        font-style: normal;
    }
    /deep/ .el-input__inner {
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #333 !important;
    }
}
</style>
