<template>
    <div class="basic">
        <h2>
            <!-- <i class="iconfont icon-icon_yingyongguanli"></i> -->
            <i class="iconfont icon-chuchangbianhao"></i>
            商机信息
        </h2>
        <div class="info">
            <div>
                <p style="min-width: 300px">
                    <span style="width: 56px">{{
                        data.nicheCategory == 1 ? '客户' : '报备渠道'
                    }}</span>
                    <em
                        @click="onDetails"
                        class="customer"
                        v-if="data.nicheCategory == 1"
                        >{{ data.companyName || '- -' }}</em
                    >
                    <em class="customer" style="color: #333" v-else>{{
                        data.channelManageName || '- -'
                    }}</em>
                </p>
                <!-- <p>
                    <span style="width: 56px">用户数</span>
                    <em>{{ returnDomain(data).number || 0 }}</em>
                </p> -->
                <p>
                    <span>合作时间</span>
                    <el-date-picker
                        size="mini"
                        style="width: 150px"
                        v-model="data.estimateCooperateTime"
                        type="date"
                        v-if="businessInfoUpdate && data.adminId == adminId"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        @change="
                            (val) => {
                                reportUpdate('estimateCooperateTime', val);
                            }
                        "
                    >
                    </el-date-picker>
                    <em v-else>{{ data.estimateCooperateTime || '- -' }}</em>
                </p>
                <p style="position: relative">
                    <span>备注</span>
                    <el-input
                        v-model="data.reportRemark"
                        size="mini"
                        type="textarea"
                        style="width: 200px; left: 53px"
                        v-if="businessInfoUpdate && data.adminId == adminId"
                        rows="3"
                        placeholder="请输入内容"
                        @blur="
                            () => {
                                reportUpdate('reportRemark', data.reportRemark);
                            }
                        "
                    ></el-input>
                    <em
                        v-else
                        class="el-textarea"
                        style="width: 200px; height: 56px; overflow-y: auto"
                        >{{ data.reportRemark || '- -' }}</em
                    >
                </p>
                <p style="position: relative; width: 25%">
                    <span>报备理由</span>
                    <el-input
                        v-model="data.reportReason"
                        size="mini"
                        type="textarea"
                        style="width: 200px"
                        v-if="businessInfoUpdate && data.adminId == adminId"
                        rows="3"
                        placeholder="请输入内容"
                        @blur="
                            () => {
                                reportUpdate('reportReason', data.reportReason);
                            }
                        "
                    ></el-input>
                    <em
                        v-else
                        class="el-textarea"
                        style="width: 200px; height: 56px; overflow-y: auto"
                        >{{ data.reportReason || '- -' }}</em
                    >
                </p>
            </div>
            <div>
                <p style="min-width: 300px">
                    <span style="width: 56px">联系人</span>
                    <!-- <el-select
                        v-model="data.contactName"
                        size="mini"
                        style="width: 150px"
                        placeholder="请选择"
                        @change="changeContactName"
                    >
                        <el-option
                            v-for="item in customerContactList"
                            :key="item.id"
                            :label="item.contactName"
                            :value="item.contactName"
                        >
                        </el-option>
                    </el-select> -->
                    <el-select
                        size="mini"
                        style="width: 150px"
                        placeholder="选择或直接输入"
                        clearable
                        allow-create
                        filterable
                        remote
                        v-model="contactData"
                        value-key="contactName"
                        v-if="businessInfoUpdate && data.adminId == adminId"
                        @change="getContactPhone"
                    >
                        <el-option
                            v-for="item in contactList"
                            :key="item.index"
                            :label="item.contactName"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                    <em v-else>{{ contactData || '- -' }}</em>
                </p>
                <p>
                    <span style="width: 56px">年限</span>
                    <el-select
                        v-model="data.estimateTerm"
                        size="mini"
                        style="width: 150px"
                        placeholder="请选择"
                        v-if="businessInfoUpdate && data.adminId == adminId"
                        @change="
                            (val) => {
                                reportUpdate('estimateTerm', val);
                            }
                        "
                    >
                        <el-option
                            v-for="item in $MailStatus.estimateTerm()"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                    <em v-else>{{ data.estimateTerm || 0 }}</em>
                </p>
            </div>
            <div>
                <p style="min-width: 300px">
                    <span>联系电话</span>
                    <!-- {{ returnContactContent() }} -->
                    <el-select
                        size="mini"
                        style="width: 150px"
                        placeholder="选择或直接输入"
                        clearable
                        v-if="businessInfoUpdate && data.adminId == adminId"
                        allow-create
                        filterable
                        remote
                        v-model="contactPhoneData"
                        value-key="contactContent"
                        @change="getPhoneData()"
                    >
                        <el-option
                            v-for="item in phoneList"
                            :key="item.index"
                            :label="item.contactContent"
                            :value="item"
                        >
                        </el-option>
                    </el-select>
                    <em v-else>{{ contactPhoneData || '- -' }}</em>
                </p>
                <p>
                    <span>预计金额</span>
                    <el-input
                        v-model="data.estimateAmount"
                        size="mini"
                        style="width: 150px"
                        placeholder="请输入内容"
                        v-if="businessInfoUpdate && data.adminId == adminId"
                        @blur="
                            () => {
                                reportUpdate(
                                    'estimateAmount',
                                    data.estimateAmount
                                );
                            }
                        "
                    ></el-input>
                    <em v-else>{{ data.estimateAmount || 0 }}</em>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { customerList, reportUpdate } from '@/api/report/report.js';
import { contactAdd } from '@/api/user/ae/customer.js';
import { contactList } from '@/api/user/ae/customer';
import { getCustomerId } from '@/api/user/ae/customer';
export default {
    name: '',
    props: {},
    data() {
        return {
            data: {},
            contactList: [],
            phoneList: [],
            contactData: {},
            contactPhoneData: {},
            change: false,
            isAddContact: false,
            businessInfoUpdate:
                Boolean(sessionStorage.getItem('businessInfoUpdate')) || false,
            adminId: sessionStorage.getItem('adminId'),
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data) {
            this.data = data;
            this.getContactList();
        },
        onDetails() {
            let data = {
                param: {
                    id: this.data.customerId,
                },
            };
            getCustomerId(data).then((res) => {
                let btn = JSON.parse(sessionStorage.getItem('isButArr'));
                let type = false;
                sessionStorage.setItem(
                    'showNum',
                    JSON.stringify({
                        saveNum: this.saveNum,
                        applyNum: this.applyNum,
                    })
                );
                for (let i in btn) {
                    if (btn[i].route == 'cusDetails') {
                        type = true;
                        break;
                    }
                }
                if (type) {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            type: type,
                            isTab: 1,
                        },
                    });
                } else {
                    this.$router.push({
                        path: '/customerdetails',
                        query: {
                            isTab: 1,
                        },
                    });
                }
                this.$formDetails.commit(
                    'detailsData',
                    JSON.stringify(res.data)
                );
                this.$choiceLabel.commit('getType', '2');
            });
        },
        getContactList() {
            let data = {
                param: {
                    customerId: this.data.customerId,
                },
            };
            contactList(data).then((res) => {
                this.contactList = res.data.list;
                if (
                    this.contactList.find(
                        (v) => v.contactName == this.data.contactName
                    ) !== undefined
                ) {
                    this.contactList.forEach((item) => {
                        if (item.contactName == this.data.contactName) {
                            this.phoneList = item.customerContactWayDOList;
                            item.customerContactWayDOList.forEach((itm) => {
                                if (
                                    itm.contactContent ==
                                        this.data.contactPhone &&
                                    itm.contactWay == 1
                                ) {
                                    this.contactData = item.contactName;
                                    this.contactPhoneData = itm.contactContent;
                                }
                            });
                        } else {
                            this.contactData = this.data.contactName;
                            this.contactPhoneData = this.data.contactPhone;
                            return;
                        }
                    });
                } else {
                    this.contactData = this.data.contactName;
                    this.contactPhoneData = this.data.contactPhone;
                    this.isAddContact = true;
                }
            });
        },
        // 获取联系人电话
        getContactPhone(row) {
            this.contactPhoneData = '';
            this.phoneList = [];
            for (let i in this.contactData.customerContactWayDOList) {
                if (
                    this.contactData.customerContactWayDOList[i].contactWay == 1
                ) {
                    this.phoneList.push(
                        this.contactData.customerContactWayDOList[i]
                    );
                }
            }
        },
        // 添加联系人
        contactAdd() {
            let data = {
                param: {
                    customerContactDO: {
                        companyName: this.data.companyName,
                        contactName: this.contactData,
                        customerContactWayDOList: [
                            {
                                contactContent: this.contactPhoneData,
                                contactWay: 1,
                            },
                        ],
                    },
                },
            };
            if (typeof this.contactData == 'object') {
                data.param.customerContactDO.contactName =
                    this.contactData.contactName;
            }
            if (typeof this.contactPhoneData == 'object') {
                data.param.customerContactWayDOList &&
                data.param.customerContactWayDOList.length
                    ? (data.param.customerContactWayDOList[0].contactPhone =
                          this.contactPhoneData.contactContent)
                    : '';
            }
            if (typeof this.contactData == 'string') {
                data.param.customerContactDO.contactName = this.contactData;
            }
            if (typeof this.contactPhoneData == 'string') {
                data.param.customerContactWayDOList &&
                data.param.customerContactWayDOList.length
                    ? (data.param.customerContactWayDOList[0].contactPhone =
                          this.contactPhoneData)
                    : '';
            }
            data.param.customerContactDO.customerId = this.data.customerId;
            data.param.customerContactDO.followAdminId =
                sessionStorage.getItem('adminId');
            contactAdd(data).then((res) => {
                if (res.code == 200) {
                    this.reportUpdate(
                        'contactName',
                        this.contact.contactName || this.contact
                    );
                }
            });
        },
        getPhoneData() {
            console.log(this.contactData, this.contactPhoneData);
            if (!this.contactData) {
                return this.$message.error('请选择联系人');
            }
            if (!this.contactPhoneData) {
                return this.$message.error('请选择联系电话');
            }
            let data = {
                param: {
                    id: this.$route.query.id,
                },
            };
            console.log(typeof this.contactData);
            if (typeof this.contactData == 'object') {
                data.param.contactName = this.contactData.contactName;
            }
            if (typeof this.contactPhoneData == 'object') {
                data.param.contactPhone = this.contactPhoneData.contactContent;
            }
            if (typeof this.contactData == 'string') {
                data.param.contactName = this.contactData;
            }
            if (typeof this.contactPhoneData == 'string') {
                data.param.contactPhone = this.contactPhoneData;
            }
            if (
                typeof this.contactData == 'string' ||
                typeof this.contactPhoneData == 'string'
            ) {
                this.contactAdd();
            }
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('修改成功');
                    this.$emit('reportGet');
                }
            });
        },

        reportUpdate(str, val) {
            let data = {
                param: {
                    [str]: val,
                    productType: 4,
                    id: this.data.id,
                },
            };
            if (str == 'contactName') {
                console.log(this.contactPhone);
                data.param.contactPhone =
                    this.contactPhone.contactContent || this.contactPhone;
            }
            reportUpdate(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('reportGet');
                }
            });
        },
        returnDomain(data) {
            if (data.nicheDomainBOList && data.nicheDomainBOList.length > 0) {
                let index = data.nicheDomainBOList.findIndex((item) => {
                    return (
                        item.auditStatus == 1 && data.trialDomain == item.domain
                    );
                });
                if (index != -1) {
                    return data.nicheDomainBOList[index];
                } else {
                    return data.nicheDomainBOList[0];
                }
            } else {
                return '- -';
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.basic {
    h2 {
        padding-left: 16px;
        padding-top: 20px;
        padding-bottom: 16px;
        font-size: 14px;
        font-weight: 600;
        i {
            font-size: 14px;
            font-weight: normal;
            color: #2370eb;
        }
    }
    .info {
        padding-left: 31px;
        padding-bottom: 12px;
        > div {
            display: flex;
            flex-wrap: nowrap;

            p {
                width: 25%;
                font-size: 14px;
                color: #333;
                font-weight: 600;
                display: flex;
                align-items: center;
                span {
                    color: #666666;
                    font-weight: normal;
                    margin-right: 10px;
                    display: inline-block;
                }
            }
        }
        > div + div {
            margin-top: 16px;
        }
    }
    /deep/ .el-input__inner {
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #333 !important;
    }
    .el-textarea {
        width: 310px;
        position: absolute;
        left: 66px;
        top: 0;

        //    height: 60px;
        /deep/ .el-textarea__inner {
            height: 72px !important;
            resize: none;
            font-size: 14px !important;
            font-weight: 600 !important;
            color: #333 !important;
        }
    }
    .customer {
        font-style: normal;
        color: #2370eb;
        cursor: pointer;
    }
    em {
        font-style: normal;
    }
}
</style>
