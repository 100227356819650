<template>
    <div class="order">
        <h2
            v-if="
                tableData &&
                tableData.length > 0 &&
                tableData[0].trialStatus != 1 &&
                tableData[0].trialStatus != 7 &&
                tableData[0].trialStatus != 8 &&
                tableData[0].trialStatus != 9
            "
        >
            <i class="iconfont icon-ziliao"></i>
            试用订单
        </h2>
        <div
            class="table"
            v-if="
                tableData &&
                tableData.length > 0 &&
                tableData[0].trialStatus != 1 &&
                tableData[0].trialStatus != 7 &&
                tableData[0].trialStatus != 8 &&
                tableData[0].trialStatus != 9
            "
        >
            <el-table
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '13px',
                    'font-weight': '600',
                }"
            >
                <el-table-column width="25"> </el-table-column>
                <el-table-column
                    prop="trialDomain"
                    width="103"
                    label="试用域名"
                >
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.trialDomain || '- -' }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column
                    prop="trialNumber"
                    width="90"
                    label="试用用户数"
                >
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column prop="trialDays" width="80" label="试用天数">
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column prop="name" width="160" label="试用到期时间">
                    <template slot-scope="scope">
                        <div>
                            <el-date-picker
                                v-if="
                                    (scope.row.trialStatus == 3 ||
                                        scope.row.trialStatus == 4) &&
                                    trialOrderUpdate
                                "
                                size="mini"
                                style="width: 150px"
                                v-model="scope.row.trialExpireTime"
                                :clearable="false"
                                value-format="yyyy-MM-dd HH:mm:ss"
                                type="date"
                                placeholder="选择到期时间"
                                @change="
                                    (val) => {
                                        reportSetting('trialExpireTime', val);
                                    }
                                "
                            >
                            </el-date-picker>
                            <span v-else>
                                {{
                                    scope.row.trialExpireTime
                                        ? scope.row.trialExpireTime.slice(0, 10)
                                        : '- -'
                                }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column prop="name" width="250" label="管理员账号">
                    <template slot-scope="scope">
                        <div>
                            <el-input
                                v-if="
                                    (scope.row.trialStatus == 3 ||
                                        scope.row.trialStatus == 4) &&
                                    trialOrderUpdate
                                "
                                v-model="scope.row.trialAccount"
                                @blur="
                                    (val) => {
                                        reportSetting(
                                            'trialAccount',
                                            scope.row.trialAccount
                                        );
                                    }
                                "
                                placeholder="请输入管理员账号"
                                size="small"
                                style="width: 240px"
                            ></el-input>
                            <span v-else>
                                {{
                                    scope.row.trialAccount
                                        ? scope.row.trialAccount
                                        : '- -'
                                }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column prop="name" width="160" label="初始密码">
                    <template slot-scope="scope">
                        <div>
                            <el-input
                                v-if="
                                    (scope.row.trialStatus == 3 ||
                                        scope.row.trialStatus == 4) &&
                                    trialOrderUpdate
                                "
                                v-model="scope.row.trialPassword"
                                @blur="
                                    (val) => {
                                        reportSetting(
                                            'trialPassword',
                                            scope.row.trialPassword
                                        );
                                    }
                                "
                                placeholder="请输入初始密码"
                                size="small"
                                style="width: 150px"
                            ></el-input>
                            <span v-else>
                                {{
                                    scope.row.trialPassword
                                        ? scope.row.trialPassword
                                        : '- -'
                                }}
                            </span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column min-width="20"> </el-table-column>
                <el-table-column prop="address" label="备注" min-width="150">
                    <template slot-scope="scope">
                        <div>
                            <el-input
                                v-model="scope.row.settingRemark"
                                size="mini"
                                v-if="trialOrderUpdate"
                                style="width: 80%"
                                placeholder="请输入备注"
                                @blur="
                                    () => {
                                        reportSetting(
                                            'settingRemark',
                                            scope.row.settingRemark
                                        );
                                    }
                                "
                            ></el-input>
                            <span v-else>{{
                                scope.row.settingRemark || '- -'
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <h2 style="" v-if="planList.length > 0">
            <i class="iconfont icon-ziliao" style="color: #f7b84f"></i>
            交付订单
        </h2>
        <div class="table" v-if="planList.length > 0">
            <el-table
                :data="planList"
                style="width: 100%"
                :header-cell-style="{
                    background: '#FAFAFA',
                    color: '#333333',
                    'font-size': '13px',
                    'font-weight': '600',
                }"
            >
                <el-table-column width="25"> </el-table-column>
                <el-table-column prop="createTime" label="下单时间">
                    <template slot-scope="scope">
                        <div>
                            {{
                                scope.row.createTime
                                    ? scope.row.createTime.slice(0, 10)
                                    : '- -'
                            }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="交付订单编号"
                    show-overflow-tooltip
                    min-width="120"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.orderPlanNo || '- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="客户名称"
                    show-overflow-tooltip
                    min-width="182"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.customerName || '- -' }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="类型" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            $MailStatus.planTypeAli(scope.row.planType)
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="状态">
                    <template slot-scope="scope">
                        {{
                            scope.row.orderStatus == 1
                                ? '未交付'
                                : scope.row.orderStatus == 2
                                ? '部分交付'
                                : scope.row.orderStatus == 3
                                ? '已交付'
                                : '- -'
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="账户数" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.number || '- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="额度" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.amount || '- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="年限" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{
                            scope.row.term ? scope.row.term + '年' : '- -'
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="下单平台" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span>{{ scope.row.libraryName || '- -' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="支付时间">
                    <template slot-scope="scope">
                        {{ scope.row.payTime || '- -' }}
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="销售订单">
                    <template slot-scope="scope">
                        {{ scope.row.orderNo || '- -' }}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import { reportSetting } from '@/api/report/report.js';

export default {
    name: '',
    props: {},
    data() {
        return {
            tableData: [],
            planList: [],
            trialOrderUpdate:
                Boolean(sessionStorage.getItem('trialOrderUpdate')) || false,
        };
    },
    components: {},
    created() {},
    methods: {
        getData(data, planList) {
            this.tableData = [data];
            this.planList = planList;
        },
        reportSetting(str, val) {
            let data = {
                param: {
                    [str]: val,
                    id: this.tableData[0].id,
                },
            };
            reportSetting(data).then((res) => {
                if (res.code == 200) {
                    this.$message.success('操作成功');
                    this.$emit('reportGet');
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.order {
    h2 {
        height: 52px;
        line-height: 52px;
        padding-left: 16px;
        font-size: 14px;
        font-weight: normal;
        i {
            font-weight: normal;
            color: #2370eb;
        }
    }
    .el-table {
        font-size: 13px !important;
    }
    /deep/ .el-input__inner {
        font-weight: 600 !important;
        font-size: 14px !important;
        color: #333 !important;
    }
    //     .el-input {
    //         border: none !important;
    //     }
    //     /deep/ input {
    //         border: none !important;
    //         background-color: #f5f5f5;
    //     }
}
</style>
